/*
6, moving yin  (-- x --)
7, static yang (-------)
8, static yin  (--   --)
9. moving yang (---o---)


* take 38 objects, of which

?    8 are of one colour = moving yang (#9)
?    2 are of a different colour = moving yin (#6)
?   11 are of a different colour = static yang (#7)
?   17 are of a different colour = static yin

*/

export const coloredsticks = [
  6,
  6,
  7,
  7,
  7,
  7,
  7,
  7,
  7,
  7,
  7,
  7,
  7,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9
];
