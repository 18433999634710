// const template = {
//   number: 0,
//   sequence: [],
//   name: "",
//   alt_name: "",
//   link: "",
//   other_titles: [],
//   reading: {
//     judgement: "",
//     image: ""
//   },
//   lines: [],
//   symbols: [],
//   icon: ""
// };

export const hexagrams = [
  // 1. HEAVEN
  {
    number: 1,
    sequence: [7, 7, 7, 7, 7, 7],
    name: "Ch'ien",
    alt_name: "Heaven",
    link: "http://www.jamesdekorne.com/GBCh/hex1.htm",
    other_titles: [
      "The Creative",
      "The Symbol of Heaven",
      "Force",
      "The Key",
      "Creativity",
      "The Originating",
      "Creative Power",
      "Primal Power",
      "The Life Force",
      "Heavenly Father"
    ],
    reading: {
      judgement:
        "Great success! Persistance in a righteous course brings reward.",
      image:
        "The symbol represents the full power of heaven, full of motion and strength. The superior man strengthens his own character with his ceaseless activity."
    },
    lines: [],
    symbols: ["Dragon", "Sage", "Heaven", "Kingdom", "Power", "Running"],
    icon: ""
  },
  // 2. EARTH, THE BEARER
  {
    number: 2,
    sequence: [8, 8, 8, 8, 8, 8],
    name: "K'un",
    alt_name: "Earth, The Bearer",
    link: "http://www.jamesdekorne.com/GBCh/hex2.htm",
    other_titles: [
      "The Receptive",
      "The Symbol of Earth",
      "Submission",
      "Passivity",
      "Field",
      "The Flow",
      "Responsive Service",
      "Natural Response",
      "The Bearer"
    ],
    reading: {
      judgement:
        "Sublime success. Think of the docility of a mare, which symbolizes advantage. The Superior Man has an objective and sets forth to try to gain it. At first he gets lost, but later find his way. Peaceful and righteous persistance brings good fortune.",
      image:
        "Think of the earth, it's capacaity, sustaining power, and devotion. Likewise, the Superior Man displays the highest virtue by embracing all things."
    },
    lines: [],
    symbols: ["Mare", "Earth", "Love", "Devotion"],
    icon: ""
  },
  // 3. GROWTH
  {
    number: 3,
    sequence: [7, 8, 8, 8, 7, 8],
    name: "Chun",
    alt_name: "Growth",
    link: "http://www.jamesdekorne.com/GBCh/hex3.htm",
    other_titles: [
      "Pushing Upward",
      "Difficulty at the Beginning",
      "The Symbol of Bursting",
      "Sprouting",
      "Hoarding",
      "Distress",
      "Organizational Growth Pains",
      "Difficult Beginnings",
      "Growing Pains",
      "Initial Obstacles",
      "Initial Hardship"
    ],
    reading: {
      judgement:
        "Difficulty, followed by great success success. Continue in a righteous direction, but do not seek some new goal or destination. One should find helpers.",
      image: ""
    },
    lines: [],
    symbols: ["Seedling"],
    icon: ""
  },
  // 4. ACQUIRING EXPERIENCE
  {
    number: 4,
    sequence: [8, 7, 8, 8, 8, 7],
    name: "Mêng",
    alt_name: "Acquiring Experience",
    link: "http://www.jamesdekorne.com/GBCh/hex4.htm",
    other_titles: [
      "Youthful Folly",
      "The Symbol of Covering",
      "Immaturity",
      "Uncultivated Growth",
      "Youth",
      "Acquiring Experience",
      "Youthful Ignorance",
      "Enveloping",
      "Folly",
      "Darkness"
    ],
    reading: {
      judgement:
        "To be acquiring experience has success. A fool receives an answer but continues to ask the same question, which is annoying.",
      image:
        "A spring at the foot of the mountain represents a young pupil learning from his teacher. The Superior Man nurtures his character by being thorough with his actions."
    },
    lines: [],
    symbols: ["Student"],
    icon: ""
  },
  // 5. WAITING (CHILL)
  {
    number: 5,
    sequence: [7, 7, 7, 8, 7, 8],
    name: "Hsü",
    alt_name: "Waiting (Chill)",
    link: "http://www.jamesdekorne.com/GBCh/hex5.htm",
    other_titles: [
      "Chill",
      "Nourishment",
      "Calculated Inaction",
      "Attending",
      "Biding One's Time",
      "Nourishment Through Inaction",
      "Waiting for Nourishment"
    ],
    reading: {
      judgement:
        "A lot of life is waiting. If you can show that you can wait, and if you have light in yourself (if you are sincere and righteous), perseverance will bring good fortune. It would be to good to embark on a journey.",
      image:
        "Clouds rise to the sky, and we wait for rain. The Superior Man is nourished and carries on, relaxed and happy as he passes this time."
    },
    lines: [],
    symbols: ["Light Rain", "Feast"],
    icon: ""
  },
  // 6. CONFLICT
  {
    number: 6,
    sequence: [8, 7, 8, 7, 7, 7],
    name: "Sung",
    alt_name: "Conflict, Contention",
    link: "http://www.jamesdekorne.com/GBCh/hex6.htm",
    other_titles: [
      "Stress",
      "Conflict",
      "The Symbol of Contention",
      "Strife",
      "Litigation",
      "Quarreling",
      "Arguing",
      "Lawsuit"
    ],
    reading: {
      judgement:
        "Despite sincere motivations, one is still met with opposition. Stopping halfway brings good fortune. Following to the end will be disastrous. ",
      image:
        "Stormy weather symbolizes contention. The Superior Man is careful, plans and considers the beginning of any enterprise."
    },
    lines: [],
    symbols: ["Lawsuit", "Quarrel"],
    icon: ""
  },
  // 7. DISCIPLINE, LEADING
  {
    number: 7,
    sequence: [8, 7, 8, 8, 8, 8],
    name: "Shih",
    alt_name: "Discipline, Leading",
    link: "http://www.jamesdekorne.com/GBCh/hex7.htm",
    other_titles: [
      "The Army",
      "Mustering",
      "The Symbol of Multitude and of Army",
      "Leading",
      "The Troops",
      "Collective Force",
      "Discipline",
      "Soldiers",
      "Group Action",
      "A Disciplined Multitude",
      "Ego Discipline",
      "Willpower"
    ],
    reading: {
      judgement:
        "The army needs discipline directed by serious intent and willpower. With experienced judgment, leadership, and righteous intent, there will be a good outcome.",
      image:
        "Water surrounded by land. The Superior Man is beneveloent and kind, trains and nourishes his spirit to accumulate an invincible unity.",
      notes: [
        "The word infantry is derived from the word infant. The ancient association was made because a good officer was expected to treat his soldiers as if they were his own children, with stern but loving discipline."
      ]
    },
    lines: [],
    symbols: ["Soldier", "General"],
    icon: ""
  },
  // 8. ALLIANCE
  {
    number: 8,
    sequence: [8, 8, 8, 8, 7, 8],
    name: "Pi",
    alt_name: "Alliance",
    link: "http://www.jamesdekorne.com/GBCh/hex8.htm",
    other_titles: [
      "The Symbol of Subaltern Assistance",
      "Union",
      "Unity",
      "Grouping",
      "Alliance",
      "Co-ordination",
      "Leadership",
      "Merging (as with tributaries of a river)",
      "Seeking Union",
      "Unification",
      "Accord",
      "Subservience",
      "Individuation",
      "Integration"
    ],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 9. THE SMALL POWERS
  {
    number: 9,
    sequence: [7, 7, 7, 8, 7, 7],
    name: "Hsiao Ch'u",
    alt_name: "The Small Powers",
    link: "http://www.jamesdekorne.com/GBCh/hex9.htm",
    other_titles: [
      "The Taming Power of the Small",
      "The Symbol of Small restraint",
      "The Lesser Nourisher",
      "Taming the Small Powers",
      "Small Accumulating",
      "Small Harvest",
      "Small Obstruction",
      "Nurturance by the Small",
      "Restraint by the Weak",
      "Restrained",
      "Minor Restraint",
      "The Weak Force",
      "The Force of the Small",
      "Weak Forces Restrain Strong Forces"
    ],
    reading: {
      judgement:
        "Small Powers, like the wind. Good on the whole. We don't get the nourishing virtues of the rain, but the wind refreshes us and makes us feel better. If things are going reasonably well, we can expect improvement, presumably when rain falls. Recognize strength found in softness, and weakness that comes with too much strength. The time is good to take care of the young, any similar undertaking.",
      image:
        "Wind blowing across the heaven. The Superior Man refines his nature in smlal ways, improves his ability and virtue, lives his beliefs.",
      notes: ["Power is accumulated by gently withholding its expression."]
    },
    lines: [],
    symbols: ["Wind", "Kids"],
    icon: ""
  },
  // 10. STEPPING CAREFULLY
  {
    number: 10,
    sequence: [7, 7, 8, 7, 7, 7],
    name: "Lü",
    alt_name: "Stepping Carefully",
    link: "http://www.jamesdekorne.com/GBCh/hex10.htm",
    other_titles: [
      "Treading",
      "Conduct",
      "The Symbol of Stepping Carefully",
      "Proper Conduct",
      "Cautious Treading",
      "Proceeding Cautiously",
      "Watch Your Step",
      "Proceed at Your Own Risk",
      "Advancing With Care"
    ],
    reading: {
      judgement:
        "Stepping carefully around a tiger. It doesn't bite! Growth and success.",
      image:
        "The Superior Man discriminates between high and low, lofty and humble, things of the spirit and of the carnal. Through this actions he fortifies the will and thinking of the people."
    },
    lines: [],
    symbols: ["Tiger"],
    icon: ""
  },
  // 11. HARMONY
  {
    number: 11,
    sequence: [7, 7, 7, 8, 8, 8],
    name: "T'ai",
    alt_name: "Peace",
    link: "http://www.jamesdekorne.com/GBCh/hex11.htm",
    other_titles: [
      "Peace",
      "The Symbol of Successfulness",
      "Prospering",
      "Pervading",
      "Greatness",
      "Tranquility",
      "Prosperity",
      "Conjunction",
      "Major Synthesis",
      "Hieros Gamos",
      "Holy Marriage"
    ],
    reading: {
      judgement:
        "The inferior declines and departs, and the great arrives. There will be good fortune with progress and success.",
      image:
        "Heaven and earth are unified, symbolizing Peace. The ruler performs Heaven's will on earth, to serve all people."
    },
    lines: [],
    symbols: ["King", "Kingdom", "Heaven"],
    icon: ""
  },
  // 12. STANDSTILL, STAGNATION
  {
    number: 12,
    sequence: [8, 8, 8, 7, 7, 7],
    name: "P'i",
    alt_name: "Standstill, Stagnation",
    link: "http://www.jamesdekorne.com/GBCh/hex12.htm",
    other_titles: [
      "Divorce",
      "Standstill",
      "The Symbol of Closing",
      "Stagnation",
      "Obstruction",
      "Obstructed",
      "Decadence",
      "Disjunction",
      "Impasse"
    ],
    reading: {
      judgement:
        "Standstill. The time is out of joint, decadance prevails and virtue is mocked.",
      image:
        "Heaven and earth do not unite. The Superior Man refuses to participate in the disorder."
    },
    lines: [],
    symbols: ["Decadence"],
    icon: ""
  },
  // 13. FRIENDSHIP
  {
    number: 13,
    sequence: [7, 8, 7, 7, 7, 7],
    name: "T'ung Jên",
    alt_name: "Friendship",
    link: "http://www.jamesdekorne.com/GBCh/hex13.htm",
    other_titles: [
      "Fellowship with Men",
      "The Symbol of Companionship",
      "Lovers",
      "Beloved Friends",
      "Like-minded persons",
      "Concording People",
      "Gathering Men",
      "Sameness with People",
      "Universal Brotherhood",
      "Fellowship",
      "Community",
      "United",
      "Human Association",
      "Union of Men",
      "Integration of Forces",
      "Minor Synthesis",
      "Cliques",
      "Concordance",
      "To Be In Accord With",
      "Confirmation"
    ],
    reading: {
      judgement:
        "Friendship. It furthers one to embark on a great undertaking or journey. It benefits the superior man to continue on his task.",
      image:
        "The Superior Man differentiates and prioritizes, sorts and evaluates his options."
    },
    lines: [],
    symbols: ["Friends"],
    icon: ""
  },
  // 14. WEALTH
  {
    number: 14,
    sequence: [7, 7, 7, 7, 8, 7],
    name: "Ta Yu",
    alt_name: "Wealth",
    link: "http://www.jamesdekorne.com/GBCh/hex14.htm",
    other_titles: [
      "Possession in Great Measure",
      "The Symbol of Great Possession",
      "Sovereignty",
      "Great Having",
      "Great Possessing",
      "The Great Possessor",
      "Great Wealth",
      "Abundance",
      "Having What is Great"
    ],
    reading: {
      judgement: "Great Possessions. Supreme success.",
      image:
        "Fire over Heaven, the image of Wealth and great treasures. The Superior Man suppresses evil and furthers virtue, and thereby follows the benevelont will of Heaven and waits upon destiny."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 15. TEMPERANCE
  {
    number: 15,
    sequence: [8, 8, 7, 8, 8, 8],
    name: "Ch'ien",
    alt_name: "Modesty",
    link: "http://www.jamesdekorne.com/GBCh/hex15.htm",
    other_titles: [
      "Modesty",
      "The Symbol of Humility",
      "Moderation",
      "Humbling",
      "Respectful/Humble",
      "Yielding/Retiring"
    ],
    reading: {
      judgement:
        "Modesty creates success. The Superior Man carries affairs through to completion.",
      image:
        "A mountain hidden within the earth. The Superior Man reduces what is in excess, and augments what is insufficient. He weighs things and creates a just balance.",
      notes:
        "1. Obtaining this hexagram implies that modesty is needed in our attitude, meaning, to allow ourself to be led without resistance. – C.K. Anthony. 2. A Humble or modest person is thought of as having an “empty or unoccupied” mind, meaning a mind without prejudice. – Chung Wu. 3. Only superior people who practice Tao know where to stop, disregard what they have and appear to have nothing."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 16. ENTHUSIASM
  {
    number: 16,
    sequence: [8, 8, 8, 7, 8, 8],
    name: "Yü",
    alt_name: "Confidence",
    link: "http://www.jamesdekorne.com/GBCh/hex16.htm",
    other_titles: [
      "The Symbol of Harmonious Joy",
      "Repose",
      "Happiness",
      "Providing-for/Provision",
      "Excess",
      "Merriment",
      "Self-confidence",
      "Contentment",
      "Harmonize",
      "Excitement",
      "Intemperance",
      "Self-deception"
    ],
    reading: {
      judgement:
        "What is of great value is a sense of perfect certainty as to the rightness of our cause.",
      image:
        "Thunder exploding out of the earth. An image of Enthusiasm. Thus the ancient kinds composed their music and honored virtue, revering their ancestors, offering to God as they worshipped Him."
    },
    lines: [],
    symbols: ["Music"],
    icon: ""
  },
  // 17. ADAPTING
  {
    number: 17,
    sequence: [7, 8, 8, 7, 7, 8],
    name: "Sui",
    alt_name: "Adapting",
    link: "http://www.jamesdekorne.com/GBCh/hex17.htm",
    other_titles: [
      "According With",
      "Acquiring Followers",
      "Adapting",
      "Adjusting",
      "To Accord With",
      "To Accompany",
      "Concordance",
      "Conformance to The Work"
    ],
    reading: {
      judgement:
        "Adapting bring success. Righteous persistance brings reward. There is no error or blame. Sublime success comes only to those who follow what is right, namely the will of heaven.",
      image:
        "The Superior Man rests peacefully at night for it is important to recuperate."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 18. REPAIR
  {
    number: 18,
    sequence: [8, 7, 7, 8, 8, 7],
    name: "Ku",
    alt_name: "Repair",
    link: "http://www.jamesdekorne.com/GBCh/hex18.htm",
    other_titles: [
      "Work On What Has Been Spoiled",
      "The Symbol of Destruction",
      "Decay",
      "Arresting of Decay",
      "Work after Spoiling",
      "Fixing",
      "Rectifying",
      "Corrupting",
      "Branch",
      "Degeneration",
      "Misdeeds"
    ],
    reading: {
      judgement:
        "Fixing what has been spoiled, setting the house in order -- will be successful. It is advantageous to embark on an undertaking or journey. What happened once will surely happen again.",
      image:
        "A Superior Man reforms, repairs himself, rectifies his thoughts and feelings, and strengthens his will, thus stirring up the people."
    },
    lines: [],
    symbols: ["Worms"],
    icon: ""
  },
  // 19. APPROACH
  {
    number: 19,
    sequence: [7, 7, 8, 8, 8, 8],
    name: "Lin",
    alt_name: "Advancing",
    link: "http://www.jamesdekorne.com/GBCh/hex19.htm",
    other_titles: [
      "The Symbol of Advance and Arrival",
      "Nearing",
      "Overseeing",
      "Condescension",
      "Getting Ahead",
      "Promotion",
      "Conduct",
      "Drawing Near",
      "Becoming Great",
      "The Forest",
      "Advance",
      "Advancing"
    ],
    reading: {
      judgement: "Two steps forward followed by one step back.",
      image:
        "The Superior Man, in spite of setbacks, remains true to the work, inexhaustible in his will to teach, and without limits in his tolerance and protection of the people."
    },
    lines: [],
    symbols: ["Forest"],
    icon: ""
  },
  // 20. CONTEMPLATION
  {
    number: 20,
    sequence: [8, 8, 8, 8, 7, 7],
    name: "Kuan",
    alt_name: "Contemplation (View)",
    link: "http://www.jamesdekorne.com/GBCh/hex20.htm",
    other_titles: [
      "View",
      "The Symbol of Steady Observation",
      "Looking Down",
      "Observation",
      "Viewing",
      "Looking Up",
      "Observing",
      "Admiration",
      "To Examine",
      "Rulers and Their Subjects",
      "Introspection",
      "Perception",
      "Contemplation of the Work"
    ],
    reading: {
      judgement:
        "The first step has been taken, but the main duties are yet to be performed. Contemplate your motivations and discern the purity of your intent.",
      image:
        "The Superior Man observed the kingdom, the people and himself. Contemplated all and gave instruction. That is to say, rectified his attitudes. "
    },
    lines: ["Arch"],
    symbols: [],
    icon: ""
  },
  // 21. DISCERNMENT
  {
    number: 21,
    sequence: [7, 8, 8, 7, 8, 7],
    name: "Shih Ho",
    alt_name: "Discernment",
    link: "http://www.jamesdekorne.com/GBCh/hex21.htm",
    other_titles: [
      "Biting Through",
      "Gnawing",
      "The Symbol of Mastication and Punishment by Pressing and Squeezing",
      "Gnawing Bite",
      "Severing",
      "Chewing",
      "Punishment",
      "Reformation",
      "Reform",
      "Differentiation",
      "Discrimination",
      "Making a Distinction",
      "Getting the message"
    ],
    reading: {
      judgement:
        "Biting Through. Carefully discern between what is true and false, right and wrong, correct and incorrect. It is favorable to let justice be administered.",
      image:
        "The image portrays the connection between cause and effect. Consequences are based on laws that exist."
    },
    lines: [],
    symbols: ["Law", "Bite"],
    icon: ""
  },
  // 22. APPEARANCE
  {
    number: 22,
    sequence: [7, 8, 7, 8, 8, 7],
    name: "Pi",
    alt_name: "Superficial Appearance",
    link: "http://www.jamesdekorne.com/GBCh/hex22.htm",
    other_titles: [
      "Grace",
      "The Symbol of Decoration",
      "Elegance",
      "Gracefulness",
      "Luxuriance",
      "Adorning",
      "Public Image",
      "Adornment",
      "Beauty",
      "Conceit",
      "Vanity",
      "Veneer",
      "Façade",
      "Manners",
      "Embellishment",
      "Superficiality",
      "Superficial Appearances",
      "Form vs. Function",
      "Art (so-called)",
      "Ego-trips"
    ],
    reading: {
      judgement:
        "Nothing with a little style, but it shouldn't make your decisions for you. It is not the actual thing.",
      image:
        "The Superior Man is graceful, witty and charming when it is appropriate. But he is shrewdly discerning when making serious choices."
    },
    lines: [],
    symbols: ["Mask"],
    icon: ""
  },
  // 23. UNRAVELING
  {
    number: 23,
    sequence: [8, 8, 8, 8, 8, 7],
    name: "Po",
    alt_name: "Spliting Apart",
    link: "http://www.jamesdekorne.com/GBCh/hex23.htm",
    other_titles: [
      "Splitting Apart",
      "The symbol of Falling or Flaying",
      "Peeling Off",
      "Decay",
      "Flaying",
      "Stripping Away",
      "Intrigue",
      "Deterioration",
      "Collapse",
      "Fracturing",
      "Tearing",
      "Disintegration",
      "Ruin",
      "Unraveling"
    ],
    reading: {
      judgement: "Do not act.",
      image:
        "The Superior Man stabilizes himself, strengthens his foundations, seeks tranquility."
    },
    lines: [],
    symbols: ["Ruin"],
    icon: ""
  },
  // 24. THE TURNING POINT
  {
    number: 24,
    sequence: [7, 8, 8, 8, 8, 8],
    name: "Fu",
    alt_name: "The Turning Point",
    link: "http://www.jamesdekorne.com/GBCh/hex24.htm",
    other_titles: [
      "The Turning Point",
      "The Symbol of Returning",
      "Revival",
      "Recovery",
      "To Repeat",
      "Renewal",
      "Restore",
      "Return to the Way",
      "Cyclic Repetition"
    ],
    reading: {
      judgement:
        "A time of progress and freedom of action. All coming and going is free from harm. The work of God is a cycle that repeats and refines itself. It's favorable to have a goal or destination in mind.",
      image: "The Superior Man pauses before he begins anew."
    },
    lines: [],
    symbols: ["Circle"],
    icon: ""
  },
  // 25. INNOCENCE
  {
    number: 25,
    sequence: [7, 8, 8, 7, 7, 7],
    name: "Wu Wang",
    alt_name: "Innocence (The Unexpected)",
    link: "http://www.jamesdekorne.com/GBCh/hex25.htm",
    other_titles: [
      "The Unexpected",
      "The Unintentional",
      "The Symbol of Freedom from Error",
      "Integrity",
      "Without Embroiling",
      "Pestilence",
      "Fidelity",
      "No Error",
      "Freedom from Vainness",
      "Instinctive Goodness",
      "The Simple",
      "Correctness",
      "Subconscious"
    ],
    reading: {
      judgement:
        "Success is only possible if you are impeccably corrent. If that is not the case, take no action at all.",
      image: "The Superior Man acts in harmony with the time."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 26. THE GREAT POWERS
  {
    number: 26,
    sequence: [7, 7, 7, 8, 8, 7],
    name: "Ta Ch'u",
    alt_name: "The Taming Power of the Great",
    link: "http://www.jamesdekorne.com/GBCh/hex26.htm",
    other_titles: [
      "The Taming Power of the Great",
      "The Great Nourisher",
      "Taming the Great Powers",
      "Great Accumulating",
      "Great Accumulation",
      "Great Storage",
      "Nurturance of the Great",
      "Great Buildup",
      "Restraint of the Great",
      "Restraint by the Strong",
      "Potential Energy",
      "The Great Taming Force",
      "Energy Under Control",
      "Power Restrained",
      "Sublimation",
      "Latent Power"
    ],
    reading: {
      judgement:
        "The Great Powers. Willpower is being able to renounce selfish indulgences and to work for the good of the whole. With such a spirit, great transformations are possible.",
      image:
        "The Superior Man studies to increase his comprehension and fortitude."
    },
    lines: [],
    symbols: ["Study"],
    icon: ""
  },
  // 27. NOURISHMENT
  {
    number: 27,
    sequence: [7, 8, 8, 8, 8, 7],
    name: "I",
    alt_name: "Nourishment",
    link: "http://www.jamesdekorne.com/GBCh/hex27.htm",
    other_titles: [
      "The Corners of the Mouth",
      "Providing Nourishment",
      "The Symbol of the Cheek and of Nourishment",
      "Jaws",
      "Lower Jaw",
      "Nurturing",
      "Swallowing",
      "Sagacious Counsel",
      "Nourishing",
      "To Feed"
    ],
    reading: {
      judgement:
        "Good fortune through firm correctness. How are you being nourished? Make sure you know what you are feeding, and determine your proper diet. In food, in words, in the content you consume.",
      image:
        "The Superior Man controls his expression and regulates his appetites.",
      notes:
        "Heaven and earth nourish all things. The sages nourish men of talent and virtue in order to reach the masses."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 28. EXCESS
  {
    number: 28,
    sequence: [8, 7, 7, 7, 7, 8],
    name: "Ta Kuo",
    alt_name: "Critical Excess",
    link: "http://www.jamesdekorne.com/GBCh/hex28.htm",
    other_titles: [
      "Preponderance of the Great",
      "The Symbol of Great Passing",
      "Excess",
      "Great Excess",
      "The Passing of Greatness",
      "Great Surpassing",
      "Great Gains",
      "Experience",
      "Greater than Great",
      "Greatness in Excess",
      "Dominance by the Mighty",
      "The Passing of Greatness",
      "Excess of the Great",
      "Law of Karma"
    ],
    reading: {
      judgement:
        "A weak beam. It's advantageous to move anywhere. Like in chess, plan a few moves ahead. Success indicated.",
      image: "The Superior Man goes his own way regardless of public opinion."
    },
    lines: [],
    symbols: ["Weak beam"],
    icon: "",
    unsplash: "https://unsplash.com/photos/W2jzh7sRCuc"
  },
  // 29. DANGER
  {
    number: 29,
    sequence: [8, 7, 8, 8, 7, 8],
    name: "K'an",
    alt_name: "Danger",
    link: "http://www.jamesdekorne.com/GBCh/hex29.htm",
    other_titles: [
      "The Abysmal",
      "The Symbol of Sinking",
      "Water",
      "The Abyss",
      "Gorge",
      "Repeating Gorge",
      "Repeated Entrapment",
      "Double Pitfall",
      "Multiple Danger",
      "Double Water",
      "The Deep",
      "Dark Forces",
      "The Perilous Pit"
    ],
    reading: {
      judgement:
        "Danger. If you possess sincerity, the mind is keen. Then whatever you do will succeed.",
      image:
        "The Superior Man maintains constantly his virtue and the sincerity of his conduct. Learns and teaches from the trials."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 30. CLARITY
  {
    number: 30,
    sequence: [7, 8, 7, 7, 8, 7],
    name: "Li",
    alt_name: "Clarity",
    link: "http://www.jamesdekorne.com/GBCh/hex30.htm",
    other_titles: [
      "The Clinging",
      "The Symbol of Brightness and of Separateness",
      "Flaming Beauty",
      "Radiance",
      "Fire",
      "The Net",
      "Allegiance",
      "The Cosmic Mean",
      "Synergy",
      "Sunlight",
      "Perception",
      "Pertaining to Comprehension",
      "The Light",
      "Consciousness",
      "Lucidity"
    ],
    reading: {
      judgement:
        "Radiance, clarity, the clinging fire. Righteous persitance brings success! Care of the cow brings good fortune. That is, looking after those in need of help.",
      image:
        "The Superior Man perpetuates the brightness, cultivating even further his brilliant virtue."
    },
    lines: [],
    symbols: ["Fire"],
    icon: ""
  },
  // 31. Initiative
  {
    number: 31,
    sequence: [8, 8, 7, 7, 7, 8],
    name: "Hsien",
    alt_name: "Intiative, Wooing",
    link: "http://www.jamesdekorne.com/GBCh/hex31.htm",
    other_titles: [
      "Influence",
      "Wooing",
      "Attraction",
      "Sensation",
      "Stimulation",
      "Conjoining",
      "Feelings",
      "Sensitivity",
      "Sensing",
      "Affection",
      "Influencing to Action",
      "Tension",
      "Seeking Union",
      "Persuasion",
      "Courting Response",
      "Importuning"
    ],
    reading: {
      judgement:
        "Heaven and earth attract one another and all creation comes into being. All success depends on the effect of mutual attraction. The sage wins hearts with wisdom, the king wins hearts with justice, youth wins a lover's a lover's heart with persistence. Keeping still bings joy to the earth, and keeps it from flying into excess. For it is boung joy, not the untethered ecstacy, which works the magic of unfluence and attraction.",
      image:
        "A mind should be humble and free from preoccuption, gathering wisdom from others."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  // 32. CONSISTENCY
  {
    number: 32,
    sequence: [8, 7, 7, 7, 8, 8],
    name: "Hêng",
    alt_name: "Duration",
    link: "http://www.jamesdekorne.com/GBCh/hex32.htm",
    other_titles: [
      "Duration",
      "The Symbol of Constancy",
      "The Long Enduring",
      "Constant",
      "Persevering",
      "Standing Fast",
      "Continuity",
      "Constancy in the Midst of Change",
      "Holding Firm"
    ],
    reading: {
      judgement:
        "Duration is not the preservation of stasis: it is the lasting movement of renewal and cycle; the circling of planets, the changing of the seasons, the rising of the sun, the breathing in and out. All things can be understood by that which gives them duration, not less the people who dedicate their lives to enduring principles and so, generation upon generation, help to form the world.",
      image:
        "Storms come and go, but the laws of their coming and going are immutable. Perhaps there was no thunder or wind today, yet there have been thunder and wind for millenia. Those walk the paths of that which endures are not made of stone: change will change them and their paths. But they will not cease walking and their determination will not stray."
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 33,
    sequence: [8, 8, 7, 7, 7, 7],
    name: "Tun",
    alt_name: "Retreat",
    link: "http://www.jamesdekorne.com/GBCh/hex33.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 34,
    sequence: [7, 7, 7, 7, 8, 8],
    name: "Ta Chuang",
    alt_name: "The Power of the Great",
    link: "http://www.jamesdekorne.com/GBCh/hex34.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 35,
    sequence: [8, 8, 8, 7, 8, 7],
    name: "Chin",
    alt_name: "Progress",
    link: "http://www.jamesdekorne.com/GBCh/hex35.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 36,
    sequence: [7, 8, 7, 8, 8, 8],
    name: "Ming I",
    alt_name: "Darkening of the Light",
    link: "http://www.jamesdekorne.com/GBCh/hex36.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 37,
    sequence: [7, 8, 7, 8, 7, 7],
    name: "Chia Jên",
    alt_name: "The Family [The Clan]",
    link: "http://www.jamesdekorne.com/GBCh/hex37.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 38,
    sequence: [7, 7, 8, 7, 8, 7],
    name: "K'uei",
    alt_name: "Opposition",
    link: "http://www.jamesdekorne.com/GBCh/hex38.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 39,
    sequence: [8, 8, 7, 8, 7, 8],
    name: "Chien",
    alt_name: "Obstruction",
    link: "http://www.jamesdekorne.com/GBCh/hex39.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 40,
    sequence: [8, 7, 8, 7, 8, 8],
    name: "Hsieh",
    alt_name: "Deliverance",
    link: "http://www.jamesdekorne.com/GBCh/hex40.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 41,
    sequence: [7, 7, 8, 8, 8, 7],
    name: "sun",
    alt_name: "Decrease",
    link: "http://www.jamesdekorne.com/GBCh/hex41.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 43,
    sequence: [7, 8, 8, 8, 7, 7],
    name: "I",
    alt_name: "Increase",
    link: "http://www.jamesdekorne.com/GBCh/hex42.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 43,
    sequence: [7, 7, 7, 7, 7, 8],
    name: "Kuai",
    alt_name: "Break-through (Resoluteness)",
    link: "http://www.jamesdekorne.com/GBCh/hex43.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 44,
    sequence: [8, 7, 7, 7, 7, 7],
    name: "Kou",
    alt_name: "Coming to Meet",
    link: "http://www.jamesdekorne.com/GBCh/hex44.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 45,
    sequence: [8, 8, 8, 7, 7, 8],
    name: "Ts'ui",
    alt_name: "Gathering Together [Massing]",
    link: "http://www.jamesdekorne.com/GBCh/hex45.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 46,
    sequence: [8, 7, 7, 8, 8, 8],
    name: "Shêng",
    alt_name: "Pushing Upward",
    link: "http://www.jamesdekorne.com/GBCh/hex46.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 47,
    sequence: [8, 7, 8, 7, 7, 8],
    name: "K'un",
    alt_name: "Oppression (Exhaustion)",
    link: "http://www.jamesdekorne.com/GBCh/hex47.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 48,
    sequence: [8, 7, 7, 8, 7, 8],
    name: "Ching",
    alt_name: "The Well",
    link: "http://www.jamesdekorne.com/GBCh/hex48.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 49,
    sequence: [7, 8, 7, 7, 7, 8],
    name: "Ko",
    alt_name: "Revolution (Molting)",
    link: "http://www.jamesdekorne.com/GBCh/hex49.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 50,
    sequence: [8, 7, 7, 7, 8, 7],
    name: "Ting",
    alt_name: "The Cauldron",
    link: "http://www.jamesdekorne.com/GBCh/hex50.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 51,
    sequence: [7, 8, 8, 7, 8, 8],
    name: "Chên",
    alt_name: "The Arousing (Shock, Thunder)",
    link: "http://www.jamesdekorne.com/GBCh/hex51.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 52,
    sequence: [8, 8, 7, 8, 8, 7],
    name: "Kên",
    alt_name: "Keeping Still, Mountain",
    link: "http://www.jamesdekorne.com/GBCh/hex52.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 53,
    sequence: [8, 8, 7, 8, 7, 7],
    name: "Chien",
    alt_name: "Developement (Gradual Progress)",
    link: "http://www.jamesdekorne.com/GBCh/hex53.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 54,
    sequence: [7, 7, 8, 7, 8, 8],
    name: "Kuei Mei",
    alt_name: "The Marrying Maiden",
    link: "http://www.jamesdekorne.com/GBCh/hex54.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 55,
    sequence: [7, 8, 7, 7, 8, 8],
    name: "Fêng",
    alt_name: "Abundance [Fullness]",
    link: "http://www.jamesdekorne.com/GBCh/hex55.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 56,
    sequence: [8, 8, 7, 7, 8, 7],
    name: "Lü",
    alt_name: "The Wanderer",
    link: "http://www.jamesdekorne.com/GBCh/hex56.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 57,
    sequence: [8, 7, 7, 8, 7, 7],
    name: "Sun",
    alt_name: "The Gentle (The Penetrating, Wind)",
    link: "http://www.jamesdekorne.com/GBCh/hex57.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 58,
    sequence: [7, 7, 8, 7, 7, 8],
    name: "Tui",
    alt_name: "The Joyous, Lake",
    link: "http://www.jamesdekorne.com/GBCh/hex58.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 59,
    sequence: [8, 7, 8, 8, 7, 7],
    name: "Huan",
    alt_name: "Dispersion [Dissolution]",
    link: "http://www.jamesdekorne.com/GBCh/hex59.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 60,
    sequence: [7, 7, 8, 8, 7, 8],
    name: "Chieh",
    alt_name: "Limitation",
    link: "http://www.jamesdekorne.com/GBCh/hex60.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 61,
    sequence: [7, 7, 8, 8, 7, 7],
    name: "Chung Fu",
    alt_name: "Inner Truth",
    link: "http://www.jamesdekorne.com/GBCh/hex61.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 62,
    sequence: [8, 8, 7, 7, 8, 8],
    name: "Hsiao Kuo",
    alt_name: "Preponderance of the Small",
    link: "http://www.jamesdekorne.com/GBCh/hex62.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 63,
    sequence: [7, 8, 7, 8, 7, 8],
    name: "Chi Chi",
    alt_name: "After Completion",
    link: "http://www.jamesdekorne.com/GBCh/hex63.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  },
  {
    number: 64,
    sequence: [8, 7, 8, 7, 8, 7],
    name: "Wei Chi",
    alt_name: "Before Completion",
    link: "http://www.jamesdekorne.com/GBCh/hex64.htm",
    other_titles: [],
    reading: {
      judgement: "",
      image: ""
    },
    lines: [],
    symbols: [],
    icon: ""
  }
];
