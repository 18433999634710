import React from "react";

import Experiment1 from "../creations/experiment1";

const ExperimentView = () => {
  return (
    <>
      <Experiment1 />
    </>
  );
};

export default ExperimentView;
